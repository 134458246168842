import {
	ProcedureRule,
	SpecIdWithMaterialIds,
	TeethRule,
	TeethUnitType,
	UnitTypeRuleWithSpecs,
	UnitTypesAdditionalRules
} from '@shared/models/rx-rules-json-interface';

export class ProcedureRulesParser<TUnitTypes extends number> {
	constructor(private procedureRules: ProcedureRule[]) {}

	setRules(rules: ProcedureRule[]): this {
		this.procedureRules = rules;
		return this;
	}

	getUnitTypeIdsByProcedureMapAndToothId(procedureMapId: number, toothId: number, currentUnitTypeOnTooth: TUnitTypes): number[] {
		const teethRules = this.getTeethRules(procedureMapId, toothId);

		const additionalUnitTypeIds: number[] = this.getAdditionalUnitTypeIds({ teethRules, toothId, currentUnitTypeOnTooth });

		const getUnitTypeIdsFromTeethRules = (accumulator: number[], teethRule: TeethRule) => {
			(teethRule.UnitAndMaterialRules.UnitTypeRules as UnitTypeRuleWithSpecs[]).forEach(unitTypeRule => {
				accumulator.push(unitTypeRule.Id);
			});

			return accumulator;
		};
		const unitTypeIds: number[] = teethRules?.reduce(getUnitTypeIdsFromTeethRules, additionalUnitTypeIds);
		return [...new Set(unitTypeIds)];
	}

	getSpecIdsWithMaterialIds(procedureMapId: number, toothId: number, currentUnitTypeOnTooth: TUnitTypes): SpecIdWithMaterialIds[] {
		const teethRules = this.getTeethRules(procedureMapId, toothId);

		const getSpecIdsFromTeethRules = (accumulator: SpecIdWithMaterialIds[], teethRule: TeethRule) => {
			const unitTypeRule = (teethRule.UnitAndMaterialRules.UnitTypeRules as UnitTypeRuleWithSpecs[]).find(
				({ Id }) => Id === currentUnitTypeOnTooth
			);

			if (unitTypeRule?.Specifications?.length) {
				accumulator.push(...unitTypeRule.Specifications);
			}

			return accumulator;
		};

		return teethRules?.reduce(getSpecIdsFromTeethRules, []);
	}

	getTeethUnitTypeRule(procedureMapId: number): TeethUnitType[] {
		const procedureRule = this.procedureRules?.find((pr: ProcedureRule) => pr.Ids.includes(procedureMapId));
		if (!procedureRule) {
			return null;
		}

		const getUnitTypeRules = (accum: TeethUnitType[], curr: TeethRule) => {
			const unitTypeIds = [];
			(curr.UnitAndMaterialRules.UnitTypeRules as UnitTypeRuleWithSpecs[]).forEach(unitTypeRule => {
				unitTypeIds.push(unitTypeRule.Id);
			});
			curr.UnitAndMaterialRules?.UnitTypesAdditionalRules?.UnitTypesAddedOutput.forEach((id: number) => {
				unitTypeIds.push(id);
			});
			accum.push({
				TeethIds: curr.Ids,
				UnitTypeIds: unitTypeIds
			});
			return accum;
		};
		return procedureRule.TeethRules.reduce(getUnitTypeRules, []);
	}

	private getTeethRules(procedureMapId: number, toothId: number): TeethRule[] {
		const procedureRule = this.getProcedureRule(procedureMapId);

		return procedureRule?.TeethRules?.filter((teethRule: TeethRule) => teethRule.Ids.includes(toothId));
	}

	private getProcedureRule(procedureMapId: number): ProcedureRule {
		return this.procedureRules?.find((procedureRule: ProcedureRule) => procedureRule.Ids.includes(procedureMapId)) ?? null;
	}

	private getAdditionalUnitTypeIds({
		teethRules,
		toothId,
		currentUnitTypeOnTooth
	}: {
		teethRules: TeethRule[];
		toothId: number;
		currentUnitTypeOnTooth: number;
	}): number[] {
		const getAdditionalUnitTypeIdsFromTeethRules = (accumulator: number[], teethRule: TeethRule) => {
			if (teethRule.Ids.includes(toothId) && teethRule.UnitAndMaterialRules.UnitTypesAdditionalRules) {
				const additionalRules: UnitTypesAdditionalRules = teethRule.UnitAndMaterialRules.UnitTypesAdditionalRules;
				if (additionalRules.UnitTypesInput.includes(currentUnitTypeOnTooth)) {
					accumulator.push(...additionalRules.UnitTypesAddedOutput);
				}
			}
			return accumulator;
		};
		return teethRules?.reduce(getAdditionalUnitTypeIdsFromTeethRules, []);
	}
}

import { AfterViewInit, Component } from '@angular/core';
import { AwarenessService } from '@modules/awareness/services/awareness.service';
import { RxForDoctorFacade } from '@modules/rx-for-doctor/rx-for-doctor.facade';
import { Platforms } from '@shared/models/enums/enums';
import { RxAppSuiteBanner } from '@shared/models/rx-models/interfaces/rx-model';
import { LocalSettingsStore } from '@shared/store/localSettings/local-settings-store';
import { Observable, shareReplay, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { defaultLanguageCode } from '@shared/models/consts';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { removeHtmlTags } from '@shared/utils/remove-html-tags';
import { BiBannerType } from '@core/services/logger/interfaces/bi.enums';
import { removeTMChar } from '@shared/utils/remove-special-char';

@Component({
	selector: 'rx-awareness-banner-design-suite',
	templateUrl: './awareness-banner-design-suite.component.html',
	styleUrls: ['./awareness-banner-design-suite.component.scss'],
	providers: [LocalSettingsStore]
})
export class AwarenessBannerDesignSuiteComponent extends BaseDestroyableComponent implements AfterViewInit {
	constructor(
		private rxForDoctorFacade: RxForDoctorFacade,
		private awarenessService: AwarenessService,
		private translateService: TranslateService
	) {
		super();
	}
	readonly platforms = Platforms;
	companyId$: Observable<number> = this.rxForDoctorFacade.companyId$;
	linkToTheLearnNow$: Observable<string> = this.rxForDoctorFacade.linkToTheLearnNowForDesignSuite$;
	showEarlyAccessSWO$: Observable<RxAppSuiteBanner> = this.awarenessService.showEarlyAccessSWO$.pipe(
		shareReplay({ refCount: true, bufferSize: 1 })
	);
	enUSTransaltionJson: any;
	ngAfterViewInit(): void {
		this.translateService
			.getTranslation(defaultLanguageCode)
			.pipe(takeUntil(this.componentAlive$))
			.subscribe(res => {
				this.enUSTransaltionJson = res;
			});
	}
	onRegisterOrLearnNowClick(
		companyId: number,
		designSuiteBannerTranslationKey: string,
		designSuiteTitleTranslationKey: string,
		bannerType: string,
		designSuiteSubtitleTranslationKey?: string
	) {
		this.rxForDoctorFacade.updateRegisterForDesignSuite(
			companyId,
			removeHtmlTags(
				removeTMChar(
					this.getEnUSTranslatedText(
						bannerType,
						designSuiteBannerTranslationKey,
						designSuiteTitleTranslationKey,
						designSuiteSubtitleTranslationKey
					)
				)
			),
			bannerType
		);
	}
	get hostPlatform(): Platforms {
		return this.rxForDoctorFacade.hostPlatform;
	}
	getEnUSTranslatedText(
		bannerType: string,
		designSuiteBannerTranslationKey: string,
		designSuiteTitleTranslationKey: string,
		designSuiteSubtitleTranslationKey: string
	): string {
		return bannerType === BiBannerType.BannerLearnMore.valueOf()
			? this.enUSTransaltionJson[designSuiteBannerTranslationKey][designSuiteTitleTranslationKey] +
					' ' +
					this.enUSTransaltionJson[designSuiteBannerTranslationKey][designSuiteSubtitleTranslationKey]
			: this.enUSTransaltionJson[designSuiteBannerTranslationKey][designSuiteTitleTranslationKey];
	}
}

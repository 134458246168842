import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'doubleHyphen'
})
export class DoubleHyphenPipe implements PipeTransform {

	transform(value: string, ...args: unknown[]): string {
		return (value) ? value : '--';
	}

}

<div mat-dialog-actions class="navigation-action">
  <button
    tlkButton
    variant="danger"
    size="s"
    id="delete-button"
    (click)="handleDelete()"
  >
    {{ 'ToothEditor.Delete' | translate }}
  </button>
</div>

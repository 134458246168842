import { Inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { getJawByToothNumber } from '@modules/teeth-diagram/models/teeth-numbering';
import { getNewTooth, Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothEditorModalActions } from '@modules/teeth-diagram/models/tooth-editor-actions.enum';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { Observable } from 'rxjs';
import { BridgeService } from './bridge.service';
import { PlatformLocation } from '@angular/common';
import { tap } from 'rxjs/operators';
import { ToothEditorStore } from '@modules/tooth-editor/state/tooth-editor-store';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { OrderQuery } from '@modules/order/state/order-query';
import { ComponentType } from '@angular/cdk/portal';
import { ToothEditorComponentBase } from '@modules/tooth-editor/base/tooth-editor-component-base';
import { TOOTH_EDITOR_COMPONENT } from '@modules/tooth-editor/injection/tokens';
import { TypeEnum } from '@modules/order/models/type.enum';

@Injectable()
export class ToothEditorService {
	constructor(
		public dialog: MatDialog,
		private bridgeService: BridgeService,
		private toothEditorStore: ToothEditorStore,
		private location: PlatformLocation,
		private shellQuery: ShellQuery,
		private orderQuery: OrderQuery,
		@Inject(TOOTH_EDITOR_COMPONENT) private toothEditor: ComponentType<ToothEditorComponentBase>
	) {}

	get isGlidewellOrder() {
		return this.orderQuery.isGlidewellOrder;
	}

	openToothEditor({
		unitType,
		teeth,
		toothClickedOn,
		allTeethInJaw,
		isBridge,
		autoFocus
	}: {
		unitType?: number;
		teeth: Tooth[];
		toothClickedOn: Tooth;
		allTeethInJaw?: Tooth[];
		isBridge?: boolean;
		autoFocus?: boolean;
	}): Observable<any> {
		const dialogRef = this.dialog.open(this.toothEditor, {
			panelClass: toothClickedOn.UnitTypeID === UnitTypes.ImplantPosition ? 'tooth-editor-small-dialog' : 'tooth-editor-dialog',
			data: { unitType, teeth, toothClickedOn, allTeethInJaw, isBridge },
			autoFocus
		});
		this.toothEditorStore.update({ isToothEditorOpen: true });
		this.location.onPopState(() => dialogRef.close());

		return dialogRef.afterClosed().pipe(tap(() => {
			this.toothEditorStore.update({ isToothEditorOpen: false });
		}));
	}

	handleModalResult({
		result,
		toothId,
		upperJaw,
		lowerJaw,
		allTeethInJaw
	}: {
		result: { teeth: Tooth[]; action: ToothEditorModalActions };
		toothId: number;
		allTeethInJaw?: Tooth[];
		upperJaw?: Tooth[];
		lowerJaw?: Tooth[];
	}) {
		const isBridge = !!allTeethInJaw;
		if (result.action === ToothEditorModalActions.Apply) {
			if (isBridge) {
				this.bridgeService.bridgeEdges[result.teeth[0].BridgeIndex] = {
					firstToothOnLeftToothId: result.teeth[0].ToothID,
					lastToothOnRightToothId: result.teeth[result.teeth.length - 1].ToothID
				};
				return this.bridgeService.getBridgeTeethToUpdate({ teethToUpdate: [...result.teeth],
					 teethInJaw: allTeethInJaw, shouldConvertDeletingToregular: this.shellQuery.isProcedureFlow});
			} else {
				return [...result.teeth];
			}
		}
		if (result.action === ToothEditorModalActions.Delete) {
			if (isBridge) {
				delete this.bridgeService.bridgeEdges[result.teeth[0].BridgeIndex];
				const bridgeToDelete = this.bridgeService.getBridgeByTooth({ tooth: result.teeth[0], upperJaw, lowerJaw });
				const teethToUpdate = bridgeToDelete.map(tooth => ({ ...getNewTooth(), UnitTypeID: UnitTypes.Regular, ToothID: tooth.ToothID }));
				return teethToUpdate;
			} else {
				const unitTypeId = this.orderQuery.procedureMap?.TypeId === TypeEnum.Full_Denture_implant_based
					? UnitTypes.Missing
					: UnitTypes.Regular;
				return [{ ...getNewTooth(), UnitTypeID: unitTypeId, ToothID: toothId }];
			}
		}
	}

	openToothEditorInBridgeMode({ tooth, upperJaw, lowerJaw }: { tooth: Tooth; upperJaw: Tooth[]; lowerJaw: Tooth[] }) {
		const isExistingBridge = !!tooth.BridgeIndex;
		const jaw = getJawByToothNumber({ toothId: tooth.ToothID });
		const allTeethInJaw = jaw === 'upper' ? upperJaw : lowerJaw;
		const bridgeIndex = this.bridgeService.getBridgeIndex({ toothClickedOn: tooth, teeth: [...upperJaw, ...lowerJaw] });
		const toothClickedOn = isExistingBridge ? tooth : { ...tooth, BridgeIndex: bridgeIndex };
		const bridgeTeeth =
			isExistingBridge &&
			this.bridgeService.getTeethInBridgeByBridgeIndex({ bridgeIndex: tooth.BridgeIndex, allTeethInJaw }).map(toothInBridge => ({
				...toothInBridge,
				UnitTypeID: null,
				ToothInBridgeTypeID: toothInBridge.ToothID === tooth.ToothID ? tooth.ToothInBridgeTypeID : toothInBridge.ToothInBridgeTypeID
			}));
		const teeth = isExistingBridge ? bridgeTeeth : [{ ...toothClickedOn }];
		return this.openToothEditor({ teeth, toothClickedOn, allTeethInJaw, isBridge: true });
	}
}

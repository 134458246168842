import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { RouterTestingModule } from '@angular/router/testing';
import { RxForDoctorComponent } from '@modules/rx-for-doctor/containers/rx-for-doctor/rx-for-doctor.component';
import { RxForLabComponent } from '@modules/rx-for-lab/rx-for-lab/rx-for-lab.component';
import { RxForModelingComponent } from '@modules/rx-for-modeling/containers/rx-for-modeling/rx-for-modeling.component';

const routes: Routes = [
	{
		path: 'rx-for-doctor',
		component: RxForDoctorComponent,
		outlet: 'rx'
	},
	{
		path: 'rx-for-lab',
		component: RxForLabComponent,
		outlet: 'rx'
	},
	{
		path: 'rx-for-modeling',
		component: RxForModelingComponent,
		outlet: 'rx'
	}
];

// We use RouterTestingModule and named outlet to enable routing without url changes because we use @angular/elements
@NgModule({
	imports: [RouterTestingModule.withRoutes(routes)],
	exports: [RouterTestingModule]
})
export class AppRoutingModule {}

import { Component, Input } from '@angular/core';
import { InfoBlockInput } from '@modules/general-rx-data/models/info-block-inputs-model';

@Component({
	selector: 'rx-info-block',
	templateUrl: './info-block.component.html',
	styleUrls: ['./info-block.component.scss']
})
export class InfoBlockComponent {
	@Input() fields: InfoBlockInput[];
}

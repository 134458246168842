import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { Observable } from 'rxjs';
import { DentureDetailsFacade } from '@modules/denture-details/denture-details.facade';
import { IdName } from '@shared/models/id-name';
import { ShadeSystem } from '@shared/models/shade-system';
import { distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { createInitialState, DentureDetailsState } from '@modules/denture-details/state/denture-details.store';

@Component({
	selector: 'rx-denture-details',
	templateUrl: './denture-details.component.html',
	styleUrls: ['./denture-details.component.scss'],
	providers: [DentureDetailsFacade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DentureDetailsComponent extends BaseDestroyableComponent implements OnInit {
	form = this.formBuilder.group<DentureDetailsState>(createInitialState());

	stages$: Observable<IdName[]> = this.facade.stages$;
	moulds$: Observable<IdName[]> = this.facade.moulds$;
	shadeSystems: ShadeSystem[] = this.facade.shadeSystems;
	teethShades$: Observable<string[]> = this.facade.teethShades$;
	gingivalShades$: Observable<IdName[]> = this.facade.gingivalShades$;
	teethShadesHasOptions$: Observable<boolean> = this.facade.shouldTeethShadeBeSelected$;
	dentureUpperSectionIsReadonly$ = this.facade.dentureUpperSectionIsReadonly$;
	dentureLowerSectionIsReadonly$ = this.facade.dentureLowerSectionIsReadonly$;
	isReadonly$ = this.facade.isReadonly$;

	get isUpperControl() {
		return this.form.controls.isUpperJawChecked;
	}

	get isLowerControl() {
		return this.form.controls.isLowerJawChecked;
	}

	get stageControl() {
		return this.form.controls.stage;
	}

	get mouldControl() {
		return this.form.controls.mould;
	}

	get shadeSystemControl() {
		return this.form.controls.shadeSystem;
	}

	get teethShadeControl() {
		return this.form.controls.teethShade;
	}

	get gingivalShadeControl() {
		return this.form.controls.gingivalShade;
	}

	constructor(
		private formBuilder: FormBuilder,
		private facade: DentureDetailsFacade
	) {
		super();
	}

	ngOnInit(): void {
		this.subscribeToUserChanges();
		this.subscribeToRxLoaded();
		this.subscribeToProgramaticallyChangingToggles();
		this.subscribeToGetDefaultShade();
		this.subscribeToDentureCopyScanChange();
	}

	private subscribeToUserChanges(): void {
		this.form.valueChanges
			.pipe(
				distinctUntilChanged(),
				tap(() => {
					const result = this.facade.updateStore(this.form.getRawValue());

					if (result.isFormValuesModified) {
						this.form.patchValue(result.formValues, { emitEvent: false });
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToRxLoaded(): void {
		this.facade
			.getLoadedRx()
			.pipe(
				tap(state => this.form.patchValue(state)),
				take(1),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToProgramaticallyChangingToggles(): void {
		this.facade.getUpperSectionToggleValue$
			.pipe(
				tap(isUpperJawChecked => this.form.patchValue({ ...this.form.getRawValue(), isUpperJawChecked })),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		this.facade.getLowerSectionToggleValue$
			.pipe(
				tap(isLowerJawChecked => this.form.patchValue({ ...this.form.getRawValue(), isLowerJawChecked })),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToGetDefaultShade(): void {
		this.facade
			.getDefaultShadeSystem()
			.pipe(
				tap(shadeSystem => {
					if (!this.shadeSystemControl.value && shadeSystem) {
						this.form.patchValue({ ...this.form.getRawValue(), shadeSystem });
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToDentureCopyScanChange(): void {
		this.facade.dentureCopyScanValue$
			.pipe(
				tap((isDentureCopyScan: boolean) => {
					this.form.patchValue({ ...this.form.getRawValue(), isDentureCopyScan });
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	get isTeethShadeEnabled() {
		return !!this.shadeSystemControl?.value;
	}
}

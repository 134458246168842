import { Injectable } from '@angular/core';
import { IBaseFacade } from '@shared/base-classes/base.facade';
import { combineLatest, Observable } from 'rxjs';
import { TeethDiagramStore } from './state/teeth-diagram-store';
import { TeethDiagramQuery } from './state/teeth-diagram-query';
import { UserSettings } from '@shared/models/user-settings';
import { TeethDiagramState } from './models/teeth-diagram';
import { CaseTypeEnum } from '@modules/order/models/case-type.enum';
import { OrderQuery } from '@modules/order/state/order-query';
import { filter, first, map, take, tap } from 'rxjs/operators';
import { IdName } from '@shared/models/id-name';
import { Tooth } from './models/tooth';
import { TeethNumberingSystem } from './models/teeth-numbering-system.enum';
import { RxRulesService } from '@shared/services/rx-rules-helper/rx-rules.service';
import { TranslateService } from '@ngx-translate/core';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { BridgeService } from '@shared/services/bridge.service';
import { UnitTypesInBridge } from './models/unit-type-in-bridge.enum';
import { getJawByToothNumber } from './models/teeth-numbering';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { NotesQuery } from '@modules/notes/state/notes-query';
import { RxNote } from '@modules/notes/models/rx-note';
import { RoleTypeEnum } from '@shared/models/role-type';
import { createdByNameIteroTeam } from '@shared/models/consts';
import { AlignTechNotesQuery } from '@modules/aligntech-notes/state/aligntech-notes-query';
import { getTeethNumberingSystem } from '@shared/utils/teeth-numbering-system-util';
import { isLegacyRestorative, isResorative } from '@shared/utils/restorative-check-util';
import { UnitTypes } from './models/unit-type.enum';
import { CacheAssetsService } from '@shared/services/cache-assets.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { AligntechNotesService } from '@modules/aligntech-notes/services/aligntech-notes.service';
import { isDentureRemovableFullDentureImplantBased } from '@shared/utils/procedure-type-util';
import { combineQueries } from '@datorama/akita';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { LoggerService } from '@core/services/logger/logger.service';
import { ReadOnlyRulesService } from '@shared/services/readOnlyRules.service';
import { ScanOptionsStore } from '@modules/scan-options/state/scan-options-store';

@Injectable()
export class TeethDiagramFacade implements IBaseFacade {
	getTeethInBridgeByBridgeIndex = this.bridgeService.getTeethInBridgeByBridgeIndex.bind(this);
	getBridgeIndex = this.bridgeService.getBridgeIndex.bind(this);
	sortBridgeTeethByToothId = this.bridgeService.sortBridgeTeethByToothId.bind(this);
	getUpdatedTeethSync = this.teethDiagramStore.getUpdatedTeethSync.bind(this);

	set bridgeIndex(bridgeIndex: number) {
		this.bridgeService.bridgeIndex = bridgeIndex;
	}

	getState$: Observable<TeethDiagramState> = this.teethDiagramQuery.teethState$;
	teeth$: Observable<{ lowerJaw: Tooth[]; upperJaw: Tooth[] }> = this.teethDiagramQuery.teeth$;
	rxTeeth$ = combineQueries([this.shellQuery.teeth$, this.shellQuery.isProcedureChanged$]).pipe(
		filter(([, isProcedureChanged]) => !isProcedureChanged),
		map(([teeth]) => teeth)
	);
	upperJaw$: Observable<Tooth[]> = this.teethDiagramQuery.upperJaw$;
	lowerJaw$: Observable<Tooth[]> = this.teethDiagramQuery.lowerJaw$;
	teethNumberingSystem$: Observable<TeethNumberingSystem> = this.teethDiagramQuery.teethNumberingSystem$;
	userSettings$: Observable<UserSettings> = this.shellQuery.userSettings$;
	bridgeTranslated$: Observable<IdName> = this.translateService.get('UnitTypes.Bridge').pipe(
		first(),
		map(name => ({ Id: null, Name: name }))
	);
	isReadOnly$: Observable<boolean> = this.readOnlyRulesService.isTeethDiagramReadOnly$;

	rx$: Observable<RxModel> = this.shellQuery.rx$;

	constructor(
		private teethDiagramStore: TeethDiagramStore,
		private teethDiagramQuery: TeethDiagramQuery,
		private rxRulesService: RxRulesService,
		private orderQuery: OrderQuery,
		private shellQuery: ShellQuery,
		private translateService: TranslateService,
		private bridgeService: BridgeService,
		private notesQuery: NotesQuery,
		private aligntechNotesQuery: AlignTechNotesQuery,
		private cacheAssetsService: CacheAssetsService,
		private notesService: NotesService,
		private aligntechNotesService: AligntechNotesService,
		private hostPlatformService: HostPlatformService,
		private logger: LoggerService,
		private readOnlyRulesService: ReadOnlyRulesService,
		private scanOptionStore: ScanOptionsStore
	) {
		void this.cacheAssetsService.loadTeethAssetsForCache();
	}

	convertToBridgeToothUnitType({ unitType }: { unitType: UnitTypes }): UnitTypesInBridge {
		return this.bridgeService.convertToBridgeToothUnitType({ unitType });
	}

	convertFromBridgeToothUnitType({ unitTypeInBridge }: { unitTypeInBridge: UnitTypesInBridge }): UnitTypes {
		return this.bridgeService.convertFromBridgeToothUnitType({ unitTypeInBridge });
	}

	getStateSync() {
		return this.teethDiagramQuery.getValue();
	}

	getRxsForPrintLength(): number {
		return this.shellQuery.rxsForPrint?.filter(
			rp => isResorative({ caseTypeId: rp.Order?.CaseTypeId }) || isLegacyRestorative({ caseTypeId: rp.Order?.CaseTypeId })
		)?.length;
	}

	getCurrentRx(): RxModel {
		return this.shellQuery.rx;
	}

	getToothById({ toothId }: { toothId: number }): Tooth {
		const teeth = [...this.getStateSync().teeth.upperJaw, ...this.getStateSync().teeth.lowerJaw];

		return teeth.find(tooth => tooth.ToothID === toothId);
	}

	updateTeethNumberingSystem(rxForPrint: RxModel) {
		combineLatest([this.shellQuery.userSettings$, this.shellQuery.rx$, this.teethDiagramQuery.forceDisplayTeethNumberingSystem$])
			.pipe(
				tap(([userSettings, rx, forceDisplayTeethNumberingSystem]: [UserSettings, RxModel, TeethNumberingSystem]) => {
					const rxToUse = rxForPrint || rx;
					const teethNumberingSystem = getTeethNumberingSystem({
						forceTeethNumberingSystem: forceDisplayTeethNumberingSystem,
						teethNumberingSystemFromUserSettings: userSettings?.ToothId,
						teethNumberingSystemFromRx: rxToUse?.ToothNumberingSystem,
						isDoctor: this.shellQuery.userRole === RoleTypeEnum.Doctor,
						logger: this.logger
					});

					this.teethDiagramStore.update({ teethNumberingSystem });
				})
			)
			.subscribe();
	}

	updateTeeth({ teethToUpdate }: { teethToUpdate: Tooth[] }) {
		this.teethDiagramStore.updateTeeth({ teethToUpdate });
		this.updateEmergenceProfile();
	}

	updatePreDefinedNote({ preDefinedNote }): void {
		const currentNotesArray = this.notesQuery.notesArray || [];
		const currentAligntechNotesArray = this.aligntechNotesQuery.alignTechNotesArray || [];
		const newNoteObject: RxNote = {
			dateCreated: new Date().toISOString(),
			content: preDefinedNote,
			role: RoleTypeEnum.Technician,
			createdByName: createdByNameIteroTeam,
			createdById: this.shellQuery.rx.ContactID,
			isPreDefinedNote: true
		};

		this.notesService.updateNotes([newNoteObject, ...currentNotesArray]);
		this.aligntechNotesService.updateAlignTechNotes([newNoteObject, ...currentAligntechNotesArray]);
	}

	getUnitTypeNames(tooth: Tooth): Observable<IdName[]> {
		if (this.shellQuery.isProcedureFlow) {
			return this.getProcedureFlowUnitTypeNames(tooth);
		}

		return this.getCaseTypeFlowUnitTypeNames(tooth);
	}

	getUnitTypeNamesForBridge(tooth: Tooth): Observable<IdName[]> {
		if (this.shellQuery.isProcedureFlow) {
			return this.getProcedureFlowUnitTypeNamesForBridge(tooth);
		}

		return this.getCaseTypeFlowUnitTypeNamesForBridge(tooth);
	}

	resetTeeth(): void {
		this.teethDiagramStore.resetTeeth();
	}

	getCaseTypeFlowUnitTypeNames(tooth: Tooth): Observable<IdName[]> {
		return combineLatest([this.rxRulesService.getCaseTypeFlowUnitTypeNames(tooth), this.bridgeTranslated$]).pipe(
			map(([unitTypeNames, bridgeTranslated]) => {
				unitTypeNames.push(bridgeTranslated);

				return unitTypeNames;
			})
		);
	}

	getProcedureFlowUnitTypeNames(tooth: Tooth): Observable<IdName[]> {
		return combineLatest([
			this.orderQuery.procedureMap$,
			this.rxRulesService.getProcedureFlowUnitTypeNames(tooth),
			this.bridgeTranslated$
		]).pipe(
			map(([procedureMap, unitTypeNames, bridgeTranslated]) => {
				if (this.rxRulesService.isBridgeAvailableFor(procedureMap.Id)) {
					return [...unitTypeNames, bridgeTranslated];
				}

				return [...unitTypeNames];
			})
		);
	}

	isFullDentureImplantBased(): boolean {
		const procedureId = this.orderQuery?.procedure?.Id;
		const typeId = this.orderQuery?.procedureMap?.TypeId;

		return isDentureRemovableFullDentureImplantBased(procedureId, typeId);
	}

	private updateEmergenceProfile(): void {
		if (!this.teethDiagramQuery.hasImplantBasedPrep && !this.hostPlatformService.isIteroModeling) {
			this.scanOptionStore.resetEmergenceProfile();
		}
	}

	private isOuterTooth(tooth: Tooth): Observable<boolean> {
		const jaw = getJawByToothNumber({ toothId: tooth.ToothID });
		const teethInJaw$ = jaw === 'upper' ? this.upperJaw$ : this.lowerJaw$;

		return teethInJaw$.pipe(
			map(teethInJaw => {
				const teethInBridge = teethInJaw.filter(
					toothInJaw => toothInJaw.BridgeIndex && toothInJaw.BridgeIndex === tooth.BridgeIndex
				);

				return this.bridgeService.isOuterTooth(tooth, teethInBridge);
			})
		);
	}

	private getCaseTypeFlowUnitTypeNamesForBridge(tooth: Tooth): Observable<IdName[]> {
		return combineLatest([this.orderQuery.caseType$, this.isOuterTooth(tooth), this.bridgeTranslated$, this.shellQuery.rx$]).pipe(
			take(1),
			map(([caseTypeItem, isOuterTooth, bridgeTranslated, existingRx]) => {
				let bridgeUnitTypeNames;
				const caseTypeItemId = caseTypeItem?.Id ?? existingRx?.Order?.CaseTypeId;

				if (caseTypeItemId === CaseTypeEnum.ChairSideMillingGlidewellIo) {
					bridgeUnitTypeNames = this.rxRulesService.getCaseTypeFlowUnitTypeNamesForGlidewellBridge(isOuterTooth);
				} else {
					bridgeUnitTypeNames = this.rxRulesService.getCaseTypeFlowUnitTypeNamesForBridge(isOuterTooth);
				}

				return [...bridgeUnitTypeNames, bridgeTranslated];
			})
		);
	}

	private getProcedureFlowUnitTypeNamesForBridge(tooth: Tooth): Observable<IdName[]> {
		return combineLatest([this.orderQuery.procedureMap$, this.isOuterTooth(tooth), this.bridgeTranslated$]).pipe(
			map(([procedureMap, isOuterTooth, bridgeTranslated]) => {
				const unitTypeNames = this.rxRulesService.getProcedureFlowUnitTypeNamesForBridge(procedureMap.Id, tooth, isOuterTooth);

				if (this.rxRulesService.isBridgeAvailableFor(procedureMap.Id)) {
					return [...unitTypeNames, bridgeTranslated];
				}

				return [...unitTypeNames];
			})
		);
	}

	get isProcedureFlow(): boolean {
		return this.shellQuery.isProcedureFlow;
	}

	get isGlidewellOrder(): boolean {
		return this.orderQuery.isGlidewellOrder;
	}
}

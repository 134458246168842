import { Component, ElementRef, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SavingFavoritesService } from '@shared/services/savingFavorites.service';
import { ObjectSelectorComponent } from '../object-selector/object-selector.component';
import { FavoritesEnum } from '@shared/models/enums/favorites.enum';
import { DropDownItem } from '@shared/models/drop-down-item';

@Component({
	selector: 'rx-favorite-selector',
	templateUrl: './favorite-selector.component.html',
	styleUrls: ['./favorite-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: FavoriteSelectorComponent,
			multi: true
		}
	]
})
export class FavoriteSelectorComponent extends ObjectSelectorComponent<DropDownItem> implements OnInit, OnChanges {
	@Input()
	favoriteName: FavoritesEnum;
	@Input()
	enableSimpleMode: boolean;

	@Input()
	minimumItemsCountToEnable = 7;
	selectedValues: Set<string>;
	@ViewChild('panel') favoritesPanel: ElementRef<HTMLInputElement>;
	private temporarySelectedValues: Set<string> = new Set<string>();
	isInEditMode = false;
	enableFavoriteManagement = true;
	constructor(injector: Injector, private savingFavoritesService: SavingFavoritesService) {
		super(injector);
	}

	ngOnInit(): void {
		if (!this.favoriteName) {
			this.enableSimpleMode = true;

			return;
		}
		if (this.enableSimpleMode) {
			return;
		}

		this.selectedValues = this.getSavedOptions();
		this.temporarySelectedValues = new Set(this.selectedValues);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	ngOnChanges(changes: SimpleChanges) {
		this.enableFavoriteManagement = !this.enableSimpleMode && this.options?.length >= this.minimumItemsCountToEnable;
	}

	switchMode() {
		this.isInEditMode = !this.isInEditMode;
	}

	setViewMode(isWindowOpened: boolean) {
		if (!isWindowOpened) {
			this.isInEditMode = false;
		}
	}

	changeSelection(favorite: DropDownItem) {
		if (this.temporarySelectedValues.has(favorite.Id)) {
			this.temporarySelectedValues.delete(favorite.Id);
		} else {
			this.temporarySelectedValues.add(favorite.Id);
		}
	}

	isTheSame(index: number, item: DropDownItem) {
		return item.Id;
	}

	showNoFavorites(favoriteOptions: DropDownItem[]) {
		return !favoriteOptions.length || favoriteOptions.every(o => o.Hidden);
	}

	getFavoriteOptions() {
		return this.options?.filter(option => this.selectedValues?.has(option.Id)) ?? [];
	}

	getOptions(viewOptions: boolean) {
		if (viewOptions) {
			return this.isInEditMode ? null : this.options?.filter(option => !this.selectedValues?.has(option.Id));
		}

		return this.isInEditMode ? this.options?.filter(option => !this.selectedValues?.has(option.Id)) : null;
	}

	saveFavorites() {
		this.savingFavoritesService.save(this.favoriteName, Array.from(this.temporarySelectedValues));
		this.selectedValues = new Set(this.temporarySelectedValues);
		this.switchMode();
		this.scrollToTop();
	}

	private getSavedOptions(): Set<string> {
		return new Set(this.savingFavoritesService.get(this.favoriteName));
	}

	private scrollToTop() {
		setTimeout(() => {
			this.favoritesPanel?.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
		}, 2);
	}
}

import { ProcedureMapEnum } from '@core/procedure-helpers/models/procedure-map.enum';
import { MaterialEnum } from '../../models/material.enum';
import { ProcedureRule } from '../../models/procedure-unit-type-material-rules-config';
import { SpecificationEnum } from '../../models/specification.enum';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { allTeeth, frontTeeth, molarTeeth } from '../teeth-sets';

export const rulesForFixedRestorative: ProcedureRule = {
	Ids: [
		ProcedureMapEnum.FixedRestorativeLabResto,
		ProcedureMapEnum.FixedRestorativeMidcOnly,
		ProcedureMapEnum.FixedRestorativeChairsideExocad,
		ProcedureMapEnum.FixedRestorativeWithoutSendTo,
		ProcedureMapEnum.FixedRestorativeSprintRay
	],
	TeethRules: [
		{
			Ids: allTeeth,
			UnitAndMaterialRules: {
				UnitTypeRules: [
					{
						Id: UnitTypes.Regular
					},
					{
						Id: UnitTypes.Missing
					},
					{
						Id: UnitTypes.Crown,
						Specifications: [
							{
								Id: SpecificationEnum.FullContour,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicTranslucentZirconia,
									MaterialEnum.CeramicMultilayerZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicFeldspathicCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.ResinAcrylicPMMA,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.PFM_PFZ,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicFeldspathicCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.PFM_PFZ_CopingOnly,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicTranslucentZirconia,
									MaterialEnum.CeramicMultilayerZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.Telescopic,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.Temporary,
								Materials: [
									MaterialEnum.ResinAcrylicPMMA,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							}
						]
					},
					{
						Id: UnitTypes.Crown_ThreeQuarters,
						Specifications: [
							{
								Id: SpecificationEnum.FullContour,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicTranslucentZirconia,
									MaterialEnum.CeramicMultilayerZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicFeldspathicCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.ResinAcrylicPMMA,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.PFM_PFZ,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicTranslucentZirconia,
									MaterialEnum.CeramicMultilayerZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicFeldspathicCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.PFM_PFZ_CopingOnly,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicTranslucentZirconia,
									MaterialEnum.CeramicMultilayerZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicFeldspathicCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.Telescopic,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.Temporary,
								Materials: [
									MaterialEnum.ResinAcrylicPMMA,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							}
						]
					},
					{
						Id: UnitTypes.ImplantBased,
						Specifications: [
							{
								Id: SpecificationEnum.FullContour,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicTranslucentZirconia,
									MaterialEnum.CeramicMultilayerZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.PFM_PFZ,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicFeldspathicCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.PFM_PFZ_CopingOnly,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicTranslucentZirconia,
									MaterialEnum.CeramicMultilayerZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.Telescopic,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							},
							{
								Id: SpecificationEnum.Temporary,
								Materials: [
									MaterialEnum.ResinAcrylicPMMA,
									MaterialEnum.PolymerPEEK,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							}
						]
					}
				],
				UnitTypesAdditionalRules: {
					UnitTypesInput: [UnitTypes.Regular],
					UnitTypesAddedOutput: [UnitTypes.Detachable]
				}
			}
		},
		{
			Ids: molarTeeth,
			UnitAndMaterialRules: {
				UnitTypeRules: [
					{
						Id: UnitTypes.Inlay,
						Specifications: [
							{
								Id: SpecificationEnum.None,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicTranslucentZirconia,
									MaterialEnum.CeramicMultilayerZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicFeldspathicCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							}
						]
					},
					{
						Id: UnitTypes.Onlay,
						Specifications: [
							{
								Id: SpecificationEnum.None,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicTranslucentZirconia,
									MaterialEnum.CeramicMultilayerZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicFeldspathicCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.MetalHighNoble,
									MaterialEnum.MetalSemiPrecious,
									MaterialEnum.MetalTitanium,
									MaterialEnum.MetalCobaltChrome,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							}
						]
					}
				]
			}
		},
		{
			Ids: frontTeeth,
			UnitAndMaterialRules: {
				UnitTypeRules: [
					{
						Id: UnitTypes.VeneerOrLaminates,
						Specifications: [
							{
								Id: SpecificationEnum.None,
								Materials: [
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicFeldspathicCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.ResinAcrylicPMMA,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote,
									MaterialEnum.OtherWax
								]
							}
						]
					}
				]
			}
		}
	]
};

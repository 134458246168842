import { Injectable } from "@angular/core";
import { UnitTypes } from "@modules/teeth-diagram/models/unit-type.enum";
import { UnitTypesInBridge } from "@modules/teeth-diagram/models/unit-type-in-bridge.enum";
import { ShellQuery } from "@shared/store/shell/shell-query";

type BridgeMapper = {
	unitTypeToBridge,
	bridgeToUnitType
};

const caseTypeFlowMapper: BridgeMapper = {
	bridgeToUnitType: {
		[UnitTypesInBridge.MarylandAbutment]: UnitTypes.Regular,
		[UnitTypesInBridge.Abutment]: UnitTypes.Crown,
		[UnitTypesInBridge.Pontic]: UnitTypes.Missing_edentulousSpace,
		[UnitTypesInBridge.Skipped]: UnitTypes.Missing_noSpace,
		[UnitTypesInBridge.Missing]: UnitTypes.Missing_noSpace,
		[UnitTypesInBridge.ImplantAbutment]: UnitTypes.ImplantAbutment,
		[UnitTypesInBridge.ScanBody]: UnitTypes.ScanBody,
		[UnitTypesInBridge.Crown]: UnitTypes.Crown,
		[UnitTypesInBridge.Inlay]: UnitTypes.Inlay,
		[UnitTypesInBridge.Onlay]: UnitTypes.Onlay,
		[UnitTypesInBridge.Veneer]: UnitTypes.Veneer
	},
	unitTypeToBridge: {
		[UnitTypes.Crown]: UnitTypesInBridge.Abutment,
		[UnitTypes.ImplantAbutment]: UnitTypesInBridge.ImplantAbutment,
		[UnitTypes.Crown_ThreeQuarters]: UnitTypesInBridge.Abutment,
		[UnitTypes.Inlay]: UnitTypesInBridge.Abutment,
		[UnitTypes.Onlay]: UnitTypesInBridge.Abutment,
		[UnitTypes.Veneer]: UnitTypesInBridge.Abutment,
		[UnitTypes.ScanBody]: UnitTypesInBridge.ScanBody,
		[UnitTypes.Detachable]: UnitTypesInBridge.Abutment,
		[UnitTypes.Missing_edentulousSpace]: UnitTypesInBridge.Pontic,
		[UnitTypes.Missing_noSpace]: UnitTypesInBridge.Skipped,
		[UnitTypes.Regular]: UnitTypesInBridge.MarylandAbutment
	}
}

const procedureFlowMapper: BridgeMapper = {
	bridgeToUnitType: {
		[UnitTypesInBridge.Abutment]: UnitTypes.Crown,
		[UnitTypesInBridge.Pontic]: UnitTypes.Missing,
		[UnitTypesInBridge.Maryland]: UnitTypes.Regular,
		[UnitTypesInBridge.Inlay]: UnitTypes.Inlay,
		[UnitTypesInBridge.Onlay]: UnitTypes.Onlay,
		[UnitTypesInBridge.ImplantBased]: UnitTypes.ImplantBased,
		[UnitTypesInBridge.Missing]: UnitTypes.Missing
	},
	unitTypeToBridge: {
		[UnitTypes.Crown]: UnitTypesInBridge.Abutment,
		[UnitTypes.Missing]: UnitTypesInBridge.Pontic,
		[UnitTypes.Regular]: UnitTypesInBridge.Maryland,
		[UnitTypes.Inlay]: UnitTypesInBridge.Inlay,
		[UnitTypes.Onlay]: UnitTypesInBridge.Onlay,
		[UnitTypes.ImplantBased]: UnitTypesInBridge.ImplantBased,
	}}

@Injectable({
	providedIn: 'root'
})
export class BridgeMappingService {

	constructor(private shellQuery: ShellQuery) {}

	unitTypeToBridge(unitType: UnitTypes): UnitTypesInBridge {
		const mapper : BridgeMapper = this.shellQuery.isProcedureFlow ? procedureFlowMapper : caseTypeFlowMapper;
		return mapper.unitTypeToBridge[unitType];
	}

	bridgeToUnitType(unitTypeInBridge: UnitTypesInBridge): UnitTypes {
		const mapper : BridgeMapper = this.shellQuery.isProcedureFlow ? procedureFlowMapper : caseTypeFlowMapper;
		return mapper.bridgeToUnitType[unitTypeInBridge];
	}
}
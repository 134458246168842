<div class="rx-card">
    <div class="rx-card-title">
        <div class="rx-title-row" fxLayout="row" fxLayoutAlign="start center">
            <div>{{ 'DentureDetails.SectionTitle' | translate }}:</div>
        </div>
    </div>
    <form class="rx-card-body" [formGroup]="form" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxLayoutAlign="stretch stretch" fxFlex="0 1 calc(50% - 15px)">
            <rx-object-selector
                [id]="'stage'"
                [label]="'DentureDetails.StageTitle' | translate"
                [formControl]="stageControl"
                [options]="stages$ | async"
                [isReadOnly]="isReadonly$ | async"
                [isCancellable]="true"
                cleanOnDisable
                name="stageControl"
            >
            </rx-object-selector>

            <rx-moulds-dropdown
                [id]="'mould'"
                [label]="'DentureDetails.MouldTitle' | translate"
                [formControl]="mouldControl"
                [options]="moulds$ | async"
                [isReadOnly]="isReadonly$ | async"
                [isCancellable]="true"
                name="mouldControl"
            >
            </rx-moulds-dropdown>

            <div fxLayout="column" fxLayoutAlign="center" fxFlex="33%">
                <div fxLayout="row" class="sides" fxLayoutAlign=" end" fxFlex="70%">
                    <label>{{ 'DentureDetails.UpperDentureTitle' | translate }}</label>
                    <mat-slide-toggle
                        fxFlex="50%"
                        color="primary"
                        [formControl]="isUpperControl"
                        name="isUpperControl"
                        [id]="'isUpper'"
                        labelPosition="before"
                        [rxIsDisabled]="(dentureUpperSectionIsReadonly$ | async) || (isReadonly$ | async)"
                    ></mat-slide-toggle>
                    <label>{{ 'DentureDetails.LowerDentureTitle' | translate }}</label>
                    <mat-slide-toggle
                        fxFlex="50%"
                        color="primary"
                        [formControl]="isLowerControl"
                        name="isLowerControl"
                        [id]="'isLower'"
                        labelPosition="before"
                        [rxIsDisabled]="(dentureLowerSectionIsReadonly$ | async) || (isReadonly$ | async)"
                    ></mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="stretch stretch" fxFlex="0 1 calc(50% - 15px)">
            <rx-object-selector
                fxFlex="33%"
                [id]="'shadeSystem'"
                [label]="'DentureDetails.ShadeSystemTitle' | translate"
                [formControl]="shadeSystemControl"
                [options]="shadeSystems"
                [isReadOnly]="isReadonly$ | async"
                [isCancellable]="false"
                name="shadeSystemControl"
            >
            </rx-object-selector>
            <rx-string-selector
                *ngIf="teethShadesHasOptions$ | async"
                [id]="'teethShade'"
                [label]="'DentureDetails.TeethShadeTitle' | translate"
                [formControl]="teethShadeControl"
                [options]="teethShades$ | async"
                name="teethShadeControl"
                [isDisabled]="!isTeethShadeEnabled"
                [isReadOnly]="isReadonly$ | async"
                [isCancellable]="true"
            >
            </rx-string-selector>
            <mat-form-field
                *ngIf="!(teethShadesHasOptions$ | async)"
                [class.disabled]="!isTeethShadeEnabled"
                [class.read-only]="isReadonly$ | async"
            >
                <mat-label>{{ 'DentureDetails.TeethShadeTitle' | translate }}</mat-label>
                <input matInput maxlength="10" [formControl]="teethShadeControl" autocomplete="off" name="teethShadeControl" />
            </mat-form-field>
            <rx-object-selector
                [id]="'gingivalShade'"
                [label]="'DentureDetails.GingivalShadeTitle' | translate"
                [formControl]="gingivalShadeControl"
                [options]="gingivalShades$ | async"
                [isReadOnly]="isReadonly$ | async"
                [isCancellable]="true"
                name="gingivalShadeControl"
            >
            </rx-object-selector>
        </div>
    </form>
</div>

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { RxNote } from '@modules/notes/models/rx-note';
import { DoctorsRxConfiguration } from '@shared/models/doctors-rx-configuration';
import { IdName } from '@shared/models/id-name';
import { LinkedCompany } from '@shared/models/linked-company';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { ScannerRxConfig } from '@shared/models/scanner-rx-config';
import { UserSettings } from '@shared/models/user-settings';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { ShellState, ShellStore } from './shell-store';
import { ImplantMasterList } from '@shared/models/implant-master-list';
import { PrintOrientation, RxVersion } from '@shared/models/enums/enums';
import { FeatureToggle } from '@shared/models/feature-toggle';
import { Material } from '@shared/models/material';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { Doctor } from '@shared/models/doctor';
import { EnvironmentParams } from '@shared/models/environment-params';
import { getDateFormatOption } from '@shared/utils/date-format-util';
import { ProcedureMap } from '@shared/models/procedure-map';
import { Procedure } from '@shared/models/procedure';
import { RxComponents } from '@shared/models/rx-models/interfaces/rx-components-model';
import { RoleTypeEnum } from '@shared/models/role-type';
import { TreatmentStage } from '@shared/models/treatment-stage';
import { CaseType } from '@shared/models/case-type';
import { Specification } from '@shared/models/specification';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ScannerVersion } from '@shared/models/company-scanner';

@Injectable({ providedIn: 'root' })
export class ShellQuery extends Query<ShellState> {
	isRxPending$: Observable<boolean> = this.select(state => state.isRxPending);
	shellState$: Observable<ShellState> = this.select(state => state);
	isLoading$: Observable<boolean> = this.select(state => state.isLoading);
	languageCode$: Observable<string> = this.select(state => state.languageCode);
	isReadOnly$: Observable<boolean> = this.select(state => state.isReadOnly);
	readOnlyRules$: Observable<RxComponents> = this.select(state => state.readOnlyRules);
	validationMode$: Observable<string> = this.select(state => state.validationMode);
	isRxTakenForScan$: Observable<boolean> = this.select(state => state.isRxTakenForScan);
	shouldAnonymizeRx$: Observable<boolean> = this.select(state => state.shouldAnonymizeRx);

	rx$: Observable<RxModel> = this.select(state => state.rx);
	clonedRx$: Observable<RxModel> = this.select(state => state.clonedRx);
	teeth$: Observable<Tooth[]> = this.select(state => state.rx?.Teeth);
	dentureDetails$ = this.select(state => state.rx?.DentureDetails);
	isProcedureChanged$ = this.select(state => state.isProcedureChanged);

	staticFilesEndpoint$: Observable<string> = this.select(state => state.staticFilesEndpoint);
	rxId$: Observable<string> = this.select(state => state.rxId);
	isReturned$: Observable<boolean> = this.select(state => state.isReturn).pipe(shareReplay({ bufferSize: 1, refCount: true }));
	orderId$: Observable<number> = this.select(state => state.orderId);
	clonedFromRxId$: Observable<string> = this.select(state => state.clonedFromRxId);
	isCloningMode$: Observable<boolean> = this.select(state => !state.isReadOnly && (!!state.clonedFromRxId || !!state.rx?.ClonedFromRxID));
	enableAllCaseTypesForAddRx$: Observable<boolean> = this.select(state => state.enableAllCaseTypesForAddRx);
	isRxSent$: Observable<boolean> = this.select(state => (state.orderId ? !!state.orderId : !!state.rx?.Order?.ID));
	preparedNewRxId$: Observable<string> = this.select(state => state.preparedNewRxId);

	attachmentsRxId$: Observable<string> = this.select(state => [state.rxId, state.orderId]).pipe(
		switchMap(([rxId, orderId]) => {
			if (rxId) {
				return of(rxId.toString());
			}

			// If we have orderId we  need to wait for rx anyway and take rxId from it
			if (orderId) {
				return this.rx$.pipe(
					filter(x => !!x),
					take(1),
					map(x => x.ID)
				);
			}

			return this.preparedNewRxId$;
		}),
		distinctUntilChanged()
	);

	isRxScanned$: Observable<boolean> = this.select(state => !!state.rx?.Order?.ScanDate);
	shipToId$ = this.select(state => state.rx?.Order?.ShipToId);
	isNewAndNotClonedRx$: Observable<boolean> = this.select(state => this.checkIsNewAndNotClonedRx(state));

	rxConfiguration$: Observable<RxConfiguration> = this.select(state => state.rxConfiguration);
	userSettings$: Observable<UserSettings> = this.select(state => state.userSettings);

	license$: Observable<string> = this.select(state => state.userSettings?.LicenseNumber);
	rxForDoctorData$: Observable<RxModel> = this.select(state => state.rx);
	directToLab$: Observable<boolean> = this.select(state => state.rx?.Order?.DirectToLab);
	// IMPORTANT! There is no new beautiful NotesArray on the BFF. We have to convert ugly string to array every time
	notesArray$: Observable<RxNote[]> = this.select(state => state.rx?.NotesArray);
	notes$: Observable<string> = this.select(state => state.rx?.Notes);
	alignTechNotes$: Observable<string> = this.select(state => state.rx?.AligntechNotes);
	alignTechNotesArray$: Observable<RxNote[]> = this.select(state => state.rx?.AlignTechNotesArray);

	contactId$: Observable<number> = this.select(state => state.contactId);
	companyId$: Observable<number> = this.select(state => state.companyId);

	doctor$: Observable<Doctor> = combineLatest([
		this.select(state => state.rxConfiguration?.Doctors),
		this.contactId$,
		this.rx$,
		this.license$
	]).pipe(
		map(([doctors, contactId, rx, license]) => {
			return [this.getCurrentDoctor(doctors, contactId, rx), license];
		}),
		map(([currentDoctor, license]: [Doctor, string]) => {
			return { Id: currentDoctor?.Id, Name: currentDoctor?.Name, Licence: currentDoctor?.Licence || license };
		})
	);
	user$: Observable<IdName> = this.select(state => state.user);

	isRxValidForSave$: Observable<boolean> = this.select(state => state.isRxValidForSave);

	shouldValidateForSend$: Observable<boolean> = this.select(state => this.checkWhetherShouldValidateForSend(state));

	companyCountryCode$: Observable<string> = this.select(state => state.rxConfiguration?.CompanyConfiguration?.CountryCode);

	productType$: Observable<string> = this.select(state => state.productType);

	applicationMode$: Observable<string> = this.select(state => state.applicationMode);

	isHeadlessPrint$: Observable<boolean> = this.select(state => state.isHeadlessPrint);

	apiEndpoint$: Observable<string> = this.select(state => state.apiEndpoint);

	caseTypes$: Observable<CaseType[]> = this.select(state => state.rxConfiguration?.CaseTypes).pipe(filter(caseTypes => !!caseTypes));
	defaultCaseTypeId$: Observable<number> = this.select(state => state.userSettings?.CaseTypeId).pipe(filter(caseTypeId => !!caseTypeId));

	procedures$: Observable<Procedure[]> = this.select(state => state.rxConfiguration?.Procedures).pipe(filter(procedures => !!procedures));
	types$: Observable<IdName[]> = this.select(state => state.rxConfiguration?.Types).pipe(filter(types => !!types));
	ponticDesigns$: Observable<IdName[]> = this.select(state => state.rxConfiguration?.PonticDesigns).pipe(
		filter(ponticDesigns => !!ponticDesigns)
	);
	defaultProcedure$: Observable<number> = this.select(state => state.userSettings?.DefaultProcedureId).pipe(
		filter(procedureId => !!procedureId)
	);
	defaultShade$: Observable<number> = this.select(state => state.userSettings?.ShadeSystemId).pipe(filter(shadeId => !!shadeId));

	proceduresMap$: Observable<ProcedureMap[]> = this.select(state => {
		return state.rxConfiguration?.RxRules?.ProceduresMap;
	});
	procedureTreatmentStages$: Observable<TreatmentStage[]> = this.select(state => state.rxConfiguration?.TreatmentStages);
	dateFormat$: Observable<string> = this.select(state => state.userSettings?.DateFormat).pipe(
		map(dateFormat => getDateFormatOption(dateFormat))
	);
	dentalLabs$: Observable<LinkedCompany[]> = this.select(state => state.rxConfiguration?.LinkedCompanies?.DentalLabs).pipe(
		filter(labs => !!labs)
	);
	directToLabs$: Observable<LinkedCompany[]> = this.select(state => state.rxConfiguration?.LinkedCompanies?.DirectToLabs).pipe(
		filter(labs => !!labs)
	);
	orthoLabs$: Observable<LinkedCompany[]> = this.select(state => state.rxConfiguration?.LinkedCompanies?.OrthoLabs).pipe(
		filter(labs => !!labs)
	);

	allLabs$: Observable<LinkedCompany[]> = combineLatest([this.dentalLabs$, this.directToLabs$, this.orthoLabs$]).pipe(
		map(([dentalLabs, directToLabs, orthoLabs]) => [...dentalLabs, ...directToLabs, ...orthoLabs])
	);

	scanners$: Observable<ScannerRxConfig[]> = this.select(state => state.rxConfiguration?.CompanyConfiguration?.Scanners);
	scannersVersions$: Observable<ScannerVersion[]> = this.select(state => state.scannersVersions);

	niriCaptureDefaultValue$: Observable<boolean> = this.select(state => state.userSettings?.NIRIEnabledDefault).pipe(
		filter(niriCaptureDefaultValue => typeof niriCaptureDefaultValue === 'boolean')
	);

	niriEnabled$: Observable<boolean> = this.select(state => state.rx?.NIRIEnabled);
	isNiriCaptureVisible$: Observable<boolean> = this.select(state => state.isNiriCaptureVisible);
	isSleeveConfirmed$: Observable<boolean> = this.select(state => state.rx?.IsSleeveConfirmed);
	isSleeveConfirmationVisible$: Observable<boolean> = this.select(state => state.isSleeveConfirmationVisible);
	isDentureCopyScan$: Observable<boolean> = this.select(state => state.rx?.DentureDetails.IsDentureCopyScan);
	prePrepScan$: Observable<boolean> = this.select(state => state.rx?.PrePrepScan);

	isPreTreatmentVisibleForV0$: Observable<boolean> = this.select(state => state.isPreTreatmentVisibleForV0);

	isScanOptionsVisible$ = combineLatest([
		this.isNiriCaptureVisible$,
		this.isSleeveConfirmationVisible$,
		this.isPreTreatmentVisibleForV0$
	]).pipe(
		map(([isNiriCaptureVisible, isSleeveConfirmationVisible, isPreTreatmentVisible]) => {
			return isNiriCaptureVisible || isSleeveConfirmationVisible || isPreTreatmentVisible;
		})
	);

	featureToggles$: Observable<FeatureToggle[]> = this.select(state => state.featureToggles);
	companySoftwareOptions$: Observable<number[]> = this.select(
		state => state.rxConfiguration?.CompanyConfiguration?.SoftwareOptionsForCompany
	);

	isSleeveConfirmationReadOnly$: Observable<boolean> = combineLatest([
		this.isReturned$,
		this.isRxSent$,
		this.getReadOnlyState('scanOptions')
	]).pipe(
		map(([isReturned, isRxSent, isReadOnly]) => {
			return !isReturned && (isRxSent || isReadOnly);
		})
	);

	isPreTreatmentReadOnly$: Observable<boolean> = combineLatest([
		this.isRxSent$,
		this.isRxScanned$,
		this.getReadOnlyState('scanOptions')
	]).pipe(
		map(([isRxSent, isRxScanned, isReadOnly]) => {
			return isRxSent || isRxScanned || isReadOnly;
		})
	);

	scannerId$: Observable<string> = this.select(state => state.scannerId);

	scanner$: Observable<ScannerRxConfig> = combineLatest([this.scannerId$, this.scanners$]).pipe(
		map(([scannerId, scanners]) => {
			const result = scanners?.find(x => x.ResourceGuid === scannerId);

			return result ?? null;
		})
	);

	clientVersion$: Observable<string> = this.select(state => state.clientVersion);
	packageVersion$: Observable<string> = this.select(state => state.packageVersion);
	/**
	 * @deprecated
	 */
	highestPackageVersion$ = this.select(state => state.rxConfiguration?.HighestScannerVersion);

	forceV0$: Observable<boolean> = this.select(state => state.forceV0);

	implantMasterList$: Observable<ImplantMasterList> = this.select(state => state.rxConfiguration?.ImplantMasterList);

	materials$: Observable<Material[]> = this.select(state => state.rxConfiguration?.Materials);
	specifications$: Observable<Specification[]> = this.select(state => state.rxConfiguration?.Specifications);

	appSettings$: Observable<EnvironmentParams> = this.select(state => state.appSettings);
	regulatoryDOBMask$: Observable<string> = this.select(state => state.rxConfiguration?.CompanyConfiguration?.RegulatorySettings?.DOBMask);
	maxPatientLastNameLength$: Observable<number> = this.select(
		state => state.rxConfiguration?.CompanyConfiguration?.RegulatorySettings?.MaxPatientLastNameLength
	);
	patientAppUrl$: Observable<string> = this.select(state => state.appSettings).pipe(map(appSettings => appSettings?.patientAppUrl));
	linkToTheLearnNowForDesignSuite$: Observable<string> = this.select(state => state.appSettings).pipe(
		map(appSettings => appSettings?.linkToTheLearnNowForDesignSuite)
	);
	authToken$: Observable<string> = this.select(state => state.authToken);

	userRole$: Observable<RoleTypeEnum> = this.select(state => state.userRole);
	rxVersionFlow$: Observable<RxVersion> = this.select(state => state.rxVersionFlow);
	isProcedureFlow$: Observable<boolean> = combineLatest([this.rxVersionFlow$, this.forceV0$]).pipe(
		map(([rxVersion, forceV0]) => !forceV0 && this.checkIsProcedureFlow(rxVersion))
	);

	restorationTypes$: Observable<IdName[]> = this.select(state => state.rxConfiguration.RestorationTypes);

	rxsForPrint$ = this.select(state => state.rxsForPrint);

	get rxSessionId(): string {
		return this.getValue().rxSessionId;
	}
	get rxConfiguration(): RxConfiguration {
		return this.getValue().rxConfiguration;
	}
	get dentalLabs(): LinkedCompany[] {
		return this.rxConfiguration?.LinkedCompanies?.DentalLabs;
	}
	get directToLabs(): LinkedCompany[] {
		return this.rxConfiguration?.LinkedCompanies?.DirectToLabs;
	}
	get orthoLabs(): LinkedCompany[] {
		return this.rxConfiguration?.LinkedCompanies?.OrthoLabs;
	}
	get caseTypes(): IdName[] {
		return this.rxConfiguration.CaseTypes;
	}
	get procedureMaps(): ProcedureMap[] {
		return this.rxConfiguration.RxRules.ProceduresMap;
	}
	get implantMasterList(): ImplantMasterList {
		return this.rxConfiguration?.ImplantMasterList;
	}
	get softwareOptionsForCompany(): number[] {
		return this.rxConfiguration.CompanyConfiguration.SoftwareOptionsForCompany;
	}
	get materials(): Material[] {
		return this.rxConfiguration.Materials;
	}
	get specifications(): Specification[] {
		return this.rxConfiguration.Specifications;
	}
	get ponticDesigns(): IdName[] {
		return this.rxConfiguration.PonticDesigns;
	}

	get userSettings(): UserSettings {
		return this.getValue().userSettings;
	}
	get dateFormat(): string {
		return this.userSettings?.DateFormat;
	}
	get license(): string {
		return this.userSettings?.LicenseNumber;
	}
	get contactId(): number {
		return this.getValue().contactId;
	}
	get companyId(): number {
		return this.getValue().companyId;
	}

	get clientVersion(): string {
		return this.getValue().clientVersion;
	}

	get packageVersion(): string {
		return this.getValue().packageVersion;
	}

	get isNiriCaptureVisible(): boolean {
		return this.getValue().isNiriCaptureVisible;
	}
	get applicationMode(): string {
		return this.getValue().applicationMode;
	}
	get apiEndpoint(): string {
		return this.getValue().apiEndpoint;
	}
	get staticFilesEndpoint(): string {
		return this.getValue().staticFilesEndpoint;
	}
	get orderId(): number {
		return this.getValue().orderId;
	}
	get rxId(): string {
		return this.getValue().rxId;
	}
	get preparedNewRxId(): string {
		return this.getValue().preparedNewRxId;
	}
	get languageCode(): string {
		return this.getValue().languageCode;
	}
	get rx(): RxModel {
		return this.getValue().rx;
	}
	get isRxPending(): boolean {
		return this.getValue().isRxPending;
	}
	get clonedRx(): RxModel {
		return this.getValue().clonedRx;
	}
	get isReadOnly(): boolean {
		return this.getValue().isReadOnly;
	}
	get authToken(): string {
		return this.getValue().authToken;
	}
	get readOnlyRules(): RxComponents {
		return this.getValue().readOnlyRules;
	}
	get isNewAndNotClonedRx(): boolean {
		return this.checkIsNewAndNotClonedRx(this.getValue());
	}
	get isRxValidForSave(): boolean {
		return this.getValue().isRxValidForSave;
	}
	get rxsForPrint(): RxModel[] {
		return this.getValue().rxsForPrint;
	}
	get printOrientation(): PrintOrientation {
		return this.getValue().printOrientation;
	}
	get isHeadlessPrint(): boolean {
		return this.getValue().isHeadlessPrint;
	}
	get clonedFromRxID(): string {
		return this.getValue().clonedFromRxId;
	}

	get doctor(): Doctor {
		const currentDoctor = this.getCurrentDoctor(this.rxConfiguration?.Doctors, this.contactId, this.rx);

		return { Id: currentDoctor?.Id, Name: currentDoctor?.Name, Licence: currentDoctor?.Licence || this.license };
	}
	get rxVersionFlow(): RxVersion {
		return this.getValue().rxVersionFlow;
	}
	get isProcedureFlow(): boolean {
		return !this.getValue().forceV0 && this.checkIsProcedureFlow(this.rxVersionFlow);
	}
	get isPatientAppDialogOpen(): boolean {
		return this.getValue().isPatientAppDialogOpen;
	}
	get userRole(): RoleTypeEnum {
		return this.getValue().userRole;
	}
	get isRxForLab(): boolean {
		return this.getValue().userRole === RoleTypeEnum.Lab;
	}
	get autoDisplayPrintViewInTheDOM(): boolean {
		return this.getValue().autoDisplayPrintViewInTheDOM;
	}
	get shouldValidateForSend(): boolean {
		return this.checkWhetherShouldValidateForSend(this.getValue());
	}
	get regulatoryDOBMask(): string {
		return this.getValue().rxConfiguration?.CompanyConfiguration?.RegulatorySettings?.DOBMask;
	}
	get companyCountryCode(): string {
		return this.getValue().rxConfiguration?.CompanyConfiguration?.CountryCode;
	}

	get printStyleName(): string {
		return this.getValue().printStyleName;
	}
	get isRxAttachmentsReady(): boolean {
		return this.getValue().isRxAttachmentsReady;
	}

	constructor(shellStore: ShellStore) {
		super(shellStore);
	}

	public getReadOnlyState(componentName: keyof RxComponents): Observable<boolean> {
		return combineLatest([this.readOnlyRules$, this.isReadOnly$]).pipe(
			map(([rules, isReadOnly]) => {
				return this.isSectionReadOnly(rules, componentName, isReadOnly);
			}),
			shareReplay({ bufferSize: 1, refCount: true })
		);
	}

	public getComponentReadOnlyState(componentName: keyof RxComponents): boolean {
		return this.isSectionReadOnly(this.readOnlyRules, componentName, this.isReadOnly);
	}

	public checkIsProcedureFlow(rxVersion: RxVersion): boolean {
		return rxVersion === RxVersion.ProcedureFlow;
	}

	private getCurrentDoctor(doctors: DoctorsRxConfiguration[], contactId: number, rx: RxModel): Doctor {
		if (!!rx) {
			return rx.Doctor as Doctor;
		}

		return doctors?.find((doctor: DoctorsRxConfiguration) => doctor.Id === contactId);
	}

	private checkIsNewAndNotClonedRx(state: ShellState): boolean {
		return !state.orderId && !state.rxId && !state.clonedFromRxId;
	}

	private checkWhetherShouldValidateForSend(state: ShellState): boolean {
		return !state.isReadOnly && state.validationMode === 'ReadyForSend';
	}

	private isSectionReadOnly(rules: RxComponents, componentName: keyof RxComponents, isReadOnly: boolean) {
		return rules?.hasOwnProperty(componentName) ? rules[componentName] : isReadOnly;
	}
}

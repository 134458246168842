import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { GeneralRxDataV1Component } from './containers/general-rx-data/v1/general-rx-data-v1.component';
import { DoubleHyphenPipe } from './pipes/double-hyphen.pipe';
import { InfoBlockComponent } from './components/info-block/info-block.component';
import { GeneralRxPrintComponent } from './containers/general-rx-print/general-rx-print.component';
import { GeneralRxDataV0Component } from '@modules/general-rx-data/containers/general-rx-data/v0/general-rx-data-v0.component';
import { GeneralOrderInfoV1Component } from './containers/general-order-info/v1/general-order-info-v1.component';
import { GeneralRxPrintV1Component } from '@modules/general-rx-data/containers/general-rx-print/v1/general-rx-print-v1.component';
import { GeneralRxPrintV0Component } from '@modules/general-rx-data/containers/general-rx-print/v0/general-rx-print-v0.component';
import { PatientInfoV1Component } from './containers/patient-info/v1/patient-info-v1.component';

@NgModule({
	declarations: [
		GeneralRxDataV1Component,
		GeneralRxDataV0Component,
		GeneralRxPrintComponent,
		GeneralRxPrintV1Component,
		GeneralRxPrintV0Component,
		DoubleHyphenPipe,
		InfoBlockComponent,
		GeneralOrderInfoV1Component,
		PatientInfoV1Component
	],
	imports: [CommonModule, SharedModule],
	exports: [GeneralRxDataV1Component, GeneralRxDataV0Component, GeneralRxPrintComponent, InfoBlockComponent, DoubleHyphenPipe]
})
export class GeneralRxDataModule {}

import { inject, InjectionToken } from "@angular/core";
import {
	CASETYPE_RULES,
	PROCEDURE_IN_BRIDGE_RULES,
	PROCEDURE_RULES,
	SPEC_MATERIAL_TOOTH_EDITOR_RULES
} from "@shared/rules/rules-tokens";
import { CaseTypeRulesParser } from "@shared/services/rx-rules-helper/case-type-rules.parser";
import { ProcedureRulesParser } from "@shared/services/rx-rules-helper/procedure-rules.parser";
import { ProcedureToothEditorParser } from "@shared/services/rx-rules-helper/procedure-tooth-editor-parser";
import { UnitTypes } from "@modules/teeth-diagram/models/unit-type.enum";
import { UnitTypesInBridge } from "@modules/teeth-diagram/models/unit-type-in-bridge.enum";

export const CASETYPE_RULES_PARSER = new InjectionToken<CaseTypeRulesParser>('case type rules parser', {
	providedIn: 'root',
	factory() {
		return new CaseTypeRulesParser(inject(CASETYPE_RULES));
	}
});
export const PROCEDURE_RULES_PARSER = new InjectionToken<ProcedureRulesParser<UnitTypes>>('procedure rules parser', {
	providedIn: 'root',
	factory() {
		return new ProcedureRulesParser(inject(PROCEDURE_RULES));
	}
});
export const PROCEDURE_IN_BRIDGE_RULES_PARSER = new InjectionToken<ProcedureRulesParser<UnitTypesInBridge>>('procedure in bridge rules parser', {
	providedIn: "root",
	factory() {
		return new ProcedureRulesParser(inject(PROCEDURE_IN_BRIDGE_RULES));
	}
});
export const PROCEDURE_TOOTH_EDITOR_PARSER = new InjectionToken('parser for spec mat rules', {
	providedIn: "root",
	factory() {
		return new ProcedureToothEditorParser(inject(SPEC_MATERIAL_TOOTH_EDITOR_RULES));
	}
})
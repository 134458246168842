import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientModule } from '@modules/patient/patient.module';
import { DoctorModule } from '@modules/doctor/doctor.module';
import { NotesModule } from '@modules/notes/notes.module';
import { OrderModule } from '@modules/order/order.module';
import { ScanOptionsModule } from '@modules/scan-options/scan-options.module';
import { TeethDiagramModule } from '@modules/teeth-diagram/teeth-diagram.module';
import { RxForDoctorComponent } from './containers/rx-for-doctor/rx-for-doctor.component';
import { SharedModule } from '@shared/shared.module';
import { ToothEditorModule } from '@modules/tooth-editor/tooth-editor.module';
import { NoticeComponent } from './components/notice/notice.component';
import { TreatmentInformationModule } from '@modules/treatment-information/treatment-information.module';
import { DentureDetailsModule } from '@modules/denture-details/denture-details.module';
import { AwarenessModule } from '@modules/awareness/awareness.module';
import { RxAttachmentsModule } from '@itero/rx-attachments';

@NgModule({
	declarations: [RxForDoctorComponent, NoticeComponent],
	imports: [
		CommonModule,
		DoctorModule,
		PatientModule,
		OrderModule,
		TeethDiagramModule,
		ToothEditorModule,
		ScanOptionsModule,
		NotesModule,
		TreatmentInformationModule,
		SharedModule,
		DentureDetailsModule,
		AwarenessModule,
		RxAttachmentsModule
	]
})
export class RxForDoctorModule {}

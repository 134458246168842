import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'rx-doctor-name',
	templateUrl: './doctor-name.component.html',
	styleUrls: ['./doctor-name.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoctorNameComponent {
	@Input() doctorName: string;
}

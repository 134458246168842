import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { defaultValues } from '@shared/models/Mappers/default-values-mapper';
import { BridgeService } from '@shared/services/bridge.service';
import { ValidateRxService } from '@shared/services/validate-rx';
import { Observable } from 'rxjs';
import { TeethDiagramState } from '../models/teeth-diagram';
import { TeethNumberingSystem } from '../models/teeth-numbering-system.enum';
import { Tooth } from '../models/tooth';
import { UnitTypes } from '../models/unit-type.enum';
import { TeethDiagramStore } from './teeth-diagram-store';
import { ObjectUtils } from '@shared/utils/object-utils/object-utils';
import { map } from 'rxjs/operators';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';

@Injectable({ providedIn: 'root' })
export class TeethDiagramQuery extends Query<TeethDiagramState> {
	teethState$: Observable<TeethDiagramState> = this.select(state => state);
	teeth$: Observable<{ lowerJaw: Tooth[]; upperJaw: Tooth[] }> = this.select(state => state.teeth);
	upperJaw$: Observable<Tooth[]> = this.select(state => state.teeth.upperJaw);
	lowerJaw$: Observable<Tooth[]> = this.select(state => state.teeth.lowerJaw);
	teethNumberingSystem$: Observable<TeethNumberingSystem> = this.select(state => state.teethNumberingSystem);
	forceDisplayTeethNumberingSystem$: Observable<TeethNumberingSystem> = this.select(state => state.forceDisplayTeethNumberingSystem);
	copiedToothId$: Observable<number> = this.select(state => state.copiedToothId);

	hasImplantBasedPrep$: Observable<boolean> = this.teeth$.pipe(
		map(teeth => {
			return this.getHasImplantBasedPrep(teeth);
		})
	);

	get teeth(): { lowerJaw: Tooth[]; upperJaw: Tooth[] } {
		return this.getValue().teeth;
	}

	get treatedTeethModel(): Tooth[] {
		const { upperJaw, lowerJaw } = this.getValue().teeth;

		return this.getTreatedTeeth(upperJaw, lowerJaw).map((tooth: Tooth) => {
			// TODO: reuse getToothBackendModel() here
			const isBridgeTooth = !!tooth.BridgeIndex;
			const toothWithBackendValues: Tooth = this.getToothWithBackendValues(tooth, isBridgeTooth);

			return this.replaceNullsByUndefined(toothWithBackendValues); // "undefined" in TypeScript --> "null" in CSharp
		});
	}
	get hasImplantBasedPrep(): boolean {
		const teeth = this.getValue().teeth;

		return this.getHasImplantBasedPrep(teeth);
	}

	getToothBackendModel(tooth: Tooth): Tooth {
		const isBridgeTooth = !!tooth.BridgeIndex;
		const toothWithBackendValues: Tooth = this.getToothWithBackendValues(tooth, isBridgeTooth);

		return this.replaceNullsByUndefined(toothWithBackendValues); // "undefined" in TypeScript --> "null" in CSharp
	}

	getToothWithBackendValues(tooth: Tooth, isBridgeTooth: boolean) {
		const unitTypeId = tooth.UnitTypeID || defaultValues.UnitTypeID.serverDefault;

		return {
			...tooth,
			UnitTypeID: isBridgeTooth
				? this.bridgeService.convertFromBridgeToothUnitType({ unitTypeInBridge: tooth.ToothInBridgeTypeID })
				: unitTypeId,
			MaterialID: tooth.MaterialID || defaultValues.MaterialID.serverDefault,
			ShadeSystemId: tooth.ShadeSystemId || defaultValues.ShadeSystemId.serverDefault,
			PonticDesignID: tooth.PonticDesignID || defaultValues.PonticDesignID.serverDefault,
			ImplantBasedRestorationTypeId: tooth.ImplantBasedRestorationTypeId || defaultValues.ImplantBasedRestorationTypeId.serverDefault
		};
	}

	get isInvalidForSendTreatedTeeth(): boolean {
		const { upperJaw, lowerJaw } = this.getValue().teeth;
		const invalidTooth = this.getTreatedTeeth(upperJaw, lowerJaw).find((tooth: Tooth) => {
			return this.validateRxService.isTreatmentInvalidForSend(tooth);
		});

		return !!invalidTooth;
	}

	get teethNumberingSystem(): TeethNumberingSystem {
		return this.getValue().teethNumberingSystem;
	}

	get copiedToothId(): string | number {
		return this.getValue().copiedToothId;
	}

	getTreatedTeeth(upperJaw: Tooth[], lowerJaw: Tooth[]): Tooth[] {
		const teeth = [...upperJaw, ...lowerJaw];

		return teeth.filter(tooth => {
			const isBridgeTooth = !!tooth.BridgeIndex;
			const isTreatedTooth = tooth.UnitTypeID !== defaultValues.UnitTypeID.serverDefault && tooth.UnitTypeID !== UnitTypes.Regular;

			return isBridgeTooth || isTreatedTooth;
		});
	}
	getHasImplantBasedPrep({ lowerJaw, upperJaw }): boolean {
		return [...upperJaw, ...lowerJaw].some(
			tooth => tooth.UnitTypeID === UnitTypes.ImplantBased || tooth.ToothInBridgeTypeID === UnitTypesInBridge.ImplantBased
		);
	}

	private replaceNullsByUndefined(tooth: Tooth): Tooth {
		return ObjectUtils.mapObject(tooth, ([, value]) => (value === null ? undefined : value));
	}

	constructor(store: TeethDiagramStore, private bridgeService: BridgeService, private validateRxService: ValidateRxService) {
		super(store);
	}
}

import { Component, Input } from '@angular/core';
import { MouldSelectorService } from '@modules/denture-details/components/mould-element/mould-selector.service';
import { IdName } from '@shared/models/id-name';

@Component({
	selector: 'rx-mould-element',
	templateUrl: './mould-element.component.html',
	styleUrls: ['./mould-element.component.scss']
})
export class MouldElementComponent {
	@Input()
	mould: IdName;

	@Input()
	isSelected: boolean;

	@Input()
	showName = true;
	constructor(private mouldSelectorService: MouldSelectorService) {}

	getMouldImage(mould: IdName): string {
		return mould ? this.mouldSelectorService.getSrcForMouldImage(mould) : '';
	}
}

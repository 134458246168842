import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'rx-license-read-only',
	templateUrl: './license-read-only.component.html',
	styleUrls: ['./license-read-only.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseReadOnlyComponent {
	@Input() license: string;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { ToothEditorHeaderComponent } from './components/tooth-editor-header/tooth-editor-header.component';
import { ToothEditorFooterComponent } from './components/tooth-editor-footer/tooth-editor-footer.component';
import { BridgeRangeSelectorComponent } from './containers/bridge-range-selector/bridge-range-selector.component';
import { ManufactureSectionComponent } from './containers/manufacture-section/manufacture-section.component';
import { AbutmentSectionComponent } from './containers/abutment-section/abutment-section.component';
import { RestorationTypeSectionComponent } from './containers/restoration-type-section/restoration-type-section.component';
import { PonticSelectorComponent } from './components/pontic-selector/pontic-selector.component';
import { ImplantPositionComponent } from './components/implant-position/implant-position.component';
import { TeethDiagramModule } from '@modules/teeth-diagram/teeth-diagram.module';

@NgModule({
	declarations: [
		ToothEditorHeaderComponent,
		ToothEditorFooterComponent,
		AbutmentSectionComponent,
		BridgeRangeSelectorComponent,
		ManufactureSectionComponent,
		RestorationTypeSectionComponent,
		PonticSelectorComponent,
		ImplantPositionComponent
	],
	imports: [CommonModule, SharedModule, TeethDiagramModule],
	exports: [
		ToothEditorHeaderComponent,
		ToothEditorFooterComponent,
		AbutmentSectionComponent,
		BridgeRangeSelectorComponent,
		ManufactureSectionComponent,
		RestorationTypeSectionComponent,
		PonticSelectorComponent,
		ImplantPositionComponent,
		CommonModule,
		SharedModule,
		TeethDiagramModule
	]
})
export class ToothEditorSharedModule {}
import { Component, Input } from '@angular/core';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { TreatmentInformationFacade } from '@modules/treatment-information/treatment-information.facade';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { ToothEditorFacade } from '@modules/tooth-editor/tooth-editor.facade';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { ImplantService } from '@modules/tooth-editor/services/implant.service';

interface ItemValue {
	Name: string;
	Value: string;
	Prefix?: string;
}

@Component({
	selector: 'rx-treatment-information-detail',
	templateUrl: './treatment-information-details.component.html',
	providers: [ToothEditorFacade],
	styleUrls: ['./treatment-information-details.component.scss']
})
export class TreatmentInformationDetailsComponent extends BaseDestroyableComponent {
	@Input() tooth: Tooth;
	@Input() material: string;
	details: Array<Array<ItemValue>>;

	constructor(
		protected facade: TreatmentInformationFacade,
		private toothEditorFacade: ToothEditorFacade,
		private implantService: ImplantService
	) {
		super();
	}

	ngOnInit(): void {
		this.details = this.getTreatmentInformationDetails();
	}

	getTreatmentInformationDetails(): Array<Array<ItemValue>> {
		if (this.tooth?.BridgeIndex > 0) {
			return this.getDetailsInBridge();
		}
		return this.getDetailsNotInBridge();
	}

	private getDetailsNotInBridge(): Array<Array<ItemValue>> {
		let prepDetails = [];
		switch (this.tooth?.UnitTypeID) {
			case UnitTypes.ImplantBased:
				prepDetails = this.getImplantBasedInfo();
				break;
			case UnitTypes.ImplantPosition:
				prepDetails = [this.getImplantManufacturer()];
				break;
			case UnitTypes.Crown:
			case UnitTypes.Inlay:
			case UnitTypes.Onlay:
			case UnitTypes.VeneerOrLaminates:
			case UnitTypes.ImplantAbutment:
				prepDetails = this.getTreatmentInfo();
				break;
			default:
				break;
		}
		return prepDetails.filter(d => d !== null && d.length !== 0);
	}

	private getDetailsInBridge(): Array<Array<ItemValue>> {
		let prepDetails = [];
		switch (this.tooth?.ToothInBridgeTypeID) {
			case UnitTypesInBridge.ImplantBased:
				prepDetails = this.getImplantBasedInfo();
				break;
			case UnitTypesInBridge.Maryland:
			case UnitTypesInBridge.Inlay:
			case UnitTypesInBridge.Onlay:
			case UnitTypesInBridge.Pontic:
			case UnitTypesInBridge.Abutment:
				prepDetails = this.getTreatmentInfo();
				break;
			default:
				break;
		}
		return prepDetails.filter(d => d !== null && d.length !== 0);
	}

	private getImplantBasedInfo(): Array<Array<ItemValue>> {
		return [
			this.getScanBodyName(),
			this.getImplantManufacturer(),
			this.getPreparationDesignBuccalAndLingual(),
			this.getMarginDesignBuccalAndLingual(),
			this.getStampfShade(),
			this.getImplantBasedRestorationType(),
			this.getAbutmentType(),
			this.getAbutmentMaterial(),
			this.getTiBase()
		];
	}

	private getTreatmentInfo(): Array<Array<ItemValue>> {
		return [this.getPreparationDesignBuccalAndLingual(), this.getMarginDesignBuccalAndLingual(), this.getStampfShade()];
	}

	private getScanBodyName(): Array<ItemValue> {
		const bodyInfo = this.implantService.getSelectedValuesByImplantId(this.tooth?.ImplantTypeID);
		if (!!bodyInfo?.implantTypeScanBody?.Name) {
			return [{ Name: 'UnitTypes.ScanBody', Value: bodyInfo?.implantTypeScanBody?.Name }];
		}
		return null;
	}

	private getImplantManufacturer(): Array<ItemValue> {
		const bodyInfo = this.implantService.getSelectedValuesByImplantId(this.tooth?.ImplantTypeID);
		if (!!bodyInfo?.implantManufacturer?.Name) {
			return [{ Name: 'ToothEditor.ImplantManufacturer', Value: bodyInfo?.implantManufacturer?.Name }];
		}
		return null;
	}

	private getPreparationDesignBuccalAndLingual(): Array<ItemValue> {
		const preparationDesignDetails: Array<ItemValue> = [];
		if (this.tooth?.PreparationDesignBuccal) {
			preparationDesignDetails.push({
				Name: 'ToothEditor.Buccal',
				Value: this.tooth.PreparationDesignBuccal,
				Prefix: 'ToothEditor.PreparationDesign'
			});
		}
		if (this.tooth?.PreparationDesignLingual) {
			preparationDesignDetails.push({
				Name: 'ToothEditor.Lingual',
				Value: this.tooth.PreparationDesignLingual,
				Prefix: 'ToothEditor.PreparationDesign'
			});
		}
		return preparationDesignDetails;
	}

	private getMarginDesignBuccalAndLingual(): Array<ItemValue> {
		const marginDetails: Array<ItemValue> = [];
		if (this.tooth?.MarginDesignBuccal) {
			marginDetails.push({ Name: 'ToothEditor.Buccal', Value: this.tooth.MarginDesignBuccal, Prefix: 'ToothEditor.MarginDesign' });
		}
		if (this.tooth?.MarginDesignLingual) {
			marginDetails.push({ Name: 'ToothEditor.Lingual', Value: this.tooth.MarginDesignLingual, Prefix: 'ToothEditor.MarginDesign' });
		}
		return marginDetails;
	}

	private getStampfShade(): Array<ItemValue> {
		if (this.tooth?.StumpfShade) {
			return [{ Name: 'TreatmentInformation.StumpShade', Value: this.tooth.StumpfShade }];
		}
		return null;
	}

	private getImplantBasedRestorationType(): Array<ItemValue> {
		if (this.tooth?.ImplantBasedRestorationTypeId) {
			const type = this.facade.getRestorationTypeName(this.tooth);
			return [{ Name: 'ToothEditor.RestorationType', Value: type }];
		}
		return null;
	}

	private getAbutmentType(): Array<ItemValue> {
		if (this.tooth?.AbutmentType) {
			const abutmentType = this.facade.getAbutementTypeName(this.tooth);
			return [{ Name: 'ToothEditor.AbutmentType', Value: abutmentType }];
		}
		return null;
	}

	private getAbutmentMaterial(): Array<ItemValue> {
		if (this.material) {
			return [{ Name: 'ToothEditor.AbutmentMaterial', Value: this.material }];
		}
		return null;
	}

	private getTiBase(): Array<ItemValue> {
		const tiBase = this.facade.getTiBase(this.tooth?.AbutmentType);
		if (tiBase != null && this.tooth?.UnitTypeID === UnitTypes.ImplantBased) {
			return [{ Name: 'Ti Base', Value: tiBase ? 'yes' : 'no' }];
		}
		return null;
	}
}

import { ProcedureMapEnum } from '@core/procedure-helpers/models/procedure-map.enum';
import { MaterialEnum } from '../../models/material.enum';
import { ProcedureRule } from '../../models/procedure-unit-type-material-rules-config';
import { SpecificationEnum } from '../../models/specification.enum';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { allTeeth, molarTeeth } from '../teeth-sets';

export const rulesForFixedRestorativeGlidewellIo: ProcedureRule = {
	Ids: [ProcedureMapEnum.FixedRestorativeGlidewellIo],
	TeethRules: [
		{
			Ids: allTeeth,
			UnitAndMaterialRules: {
				UnitTypeRules: [
					{
						Id: UnitTypes.Regular
					},
					{
						Id: UnitTypes.Crown,
						Specifications: [
							{
								Id: SpecificationEnum.None,
								Materials: [
									MaterialEnum.CeramicZirconia,
									MaterialEnum.CeramicTranslucentZirconia,
									MaterialEnum.CeramicMultilayerZirconia,
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicFeldspathicCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.ResinAcrylicPMMA,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							}
						]
					}
				]
			}
		},
		{
			Ids: molarTeeth,
			UnitAndMaterialRules: {
				UnitTypeRules: [
					{
						Id: UnitTypes.Inlay,
						Specifications: [
							{
								Id: SpecificationEnum.None,
								Materials: [
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicFeldspathicCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							}
						]
					},
					{
						Id: UnitTypes.Onlay,
						Specifications: [
							{
								Id: SpecificationEnum.None,
								Materials: [
									MaterialEnum.CeramicLithiumDisilicate,
									MaterialEnum.CeramicLithiumSilicate,
									MaterialEnum.CeramicGlassCeramic,
									MaterialEnum.CeramicFeldspathicCeramic,
									MaterialEnum.CeramicHybrid,
									MaterialEnum.CeramicLabPreference,
									MaterialEnum.Composite,
									MaterialEnum.OtherCheckNote
								]
							}
						]
					}
				]
			}
		}
	]
};

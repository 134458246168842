<div [class.partially-read-only]="isReadOnly$ | async">
  <rx-unit-type *ngIf="(isReadOnly$ | async) === false" [toothClicked]="unitTypeMenuInput$ | async" (unitTypeSelected)="handleUnitTypeSelection($event)"
    (unitTypeInBridgeSelected)="handleUnitTypeInBridgeSelection($event)"></rx-unit-type>
  <div class="tooth-editor">
    <rx-tooth-editor-header [isReadOnly]="isReadOnly$ | async" [isBridge]="isBridge$ | async" [unitTypeName]="unitTypeName$ | async"
      (modalClosed)="handleModalClose($event)">
    </rx-tooth-editor-header>
    <rx-banner *ngIf="isReadOnlyBannerVisible() | async" [type]="'info'" [message]="'ReadOnlyMode.ReadOnlyMessage' | translate"></rx-banner>
    <rx-mini-teeth-diagram [isReadOnly]="isReadOnly$ | async" [teeth]="teeth$ | async" [toothClickedOn]="toothClickedOn$ | async"
      [teethNumberingSystem]="teethNumberingSystem$ | async" (toothClicked)="handleToothClick($event)" [isGlidewellOrder]="isGlidewellOrder">
    </rx-mini-teeth-diagram>
  </div>
  <div mat-dialog-content class="tooth-editor-body">
    <div *ngIf="(isScanBody$ | async) === false">
      <rx-crown-section [toothClickedOn]="toothClickedOn$ | async" [isReadOnly]="isReadOnly$ | async"></rx-crown-section>
    </div>
    <div *ngIf="(isScanBody$ | async)" id="tooth-editor-body-container">
      <mat-accordion [multi]="true">
        <mat-expansion-panel id="scan-body-expansion-panel" togglePosition="before">
          <mat-expansion-panel-header class="rx-expansion-header">
            <mat-panel-title id="scan-body-expansion-panel-title">
              {{ 'ToothEditor.ScanBody' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <rx-manufacture-section [toothClickedOn]="toothClickedOn$ | async" class="manufacture-section" [isReadOnly]="isReadOnly$ | async"></rx-manufacture-section>
        </mat-expansion-panel>

				<mat-expansion-panel
					*ngIf="(isScanBody$ | async)"
          togglePosition="before"
					id="abutment-expansion-panel"
				>
					<mat-expansion-panel-header class="rx-expansion-header">
						<mat-panel-title id="abutment-expansion-panel-title">
							{{ 'ToothEditor.Abutment' | translate }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<rx-abutment-section
						[toothClickedOn]="toothClickedOn$ | async"
						[isReadOnly]="isReadOnly$ | async"
					></rx-abutment-section>
				</mat-expansion-panel>

        <mat-expansion-panel id="crown-expansion-panel" togglePosition="before">
          <mat-expansion-panel-header class="rx-expansion-header">
            <mat-panel-title id="crown-expansion-panel-title">
              {{ 'ToothEditor.Crown' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <rx-crown-section [toothClickedOn]="toothClickedOn$ | async"  [isReadOnly]="isReadOnly$ | async"></rx-crown-section>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <rx-tooth-editor-footer *ngIf="(isReadOnly$ | async) === false" (modalClosed)="handleModalClose($event)"></rx-tooth-editor-footer>
</div>